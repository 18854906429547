import { createRouter, createWebHashHistory, RouteRecordRaw,createWebHistory } from 'vue-router'
import Example from '../views/Example/index.vue'
import beforeEach from './beforeEach'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/404',
    name: 'page404',   //必填
    meta: { name: 'page404' },
    component: () => import('../views/404/index.vue')
  },
  {
    path: '/home',
    name: 'home',   //必填
    meta: { name: 'home' },
    component: () => import('../views/home/index.vue')
  },{
    path: '/history',
    name: 'history',   //必填
    meta: { name: 'history' },
    component: () => import('../views/history/index.vue')
  },{
    path: '/prize',
    name: 'prize',   //必填
    meta: { name: 'prize' },
    component: () => import('../views/prize/index.vue')
  },{
    path: '/index',
    name: 'index',   //必填
    meta: { name: 'index' },
    component: () => import('../views/index/index.vue')
  },{
    path: '/publicty',
    name: 'publicty',   //必填
    meta: { name: 'publicty' },
    component: () => import('../views/publicty/index.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
/**
 * 路由守卫
 */
router.beforeEach((guard) => {
  beforeEach.checkAuth(guard, router)
})

router.afterEach((to, from) => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  if (to.meta.backHide) {
    // @ts-ignore
    // upsdk.setTitleStyle({
    //   // 可选，导航栏(含状态栏)背景色及透明度。16进制，前2位(8F)透明度，后六位(FFFFFF)颜色，仅对当前页有效，其余页还是申请配置的导航默认颜色。
    //   navBackgroundColor: '0x8FFFFFFF',
    //   appletStyle: 'black', //可选，black-黑色主题，white-白色主题。 
    //   backBtnVisible: '0', // 可选，左侧返回按钮是否显示。’0’不显示，’1’显示，不传或空则默认显示 
    //   appletTitleBarVisible: '1', // 可选，标题栏是否显示。’0’不显示，’1’显示，默认显示
    //   appletTitleGradientOrient: '', // 可选，渐变色方向，支持top、bottom、left、right
    //   appletTitleGradientStartColor: '',//渐变起始颜色
    //   appletTitleGradientEndColor: '' //渐变结束颜色
    // })
  } else {
    // @ts-ignore
    // upsdk.setTitleStyle({
    //   // 可选，导航栏(含状态栏)背景色及透明度。16进制，前2位(8F)透明度，后六位(FFFFFF)颜色，仅对当前页有效，其余页还是申请配置的导航默认颜色。
    //   navBackgroundColor: '0x8FFFFFFF',
    //   appletStyle: 'black', //可选，black-黑色主题，white-白色主题。 
    //   backBtnVisible: '1', // 可选，左侧返回按钮是否显示。’0’不显示，’1’显示，不传或空则默认显示 
    //   appletTitleBarVisible: '1', // 可选，标题栏是否显示。’0’不显示，’1’显示，默认显示
    //   appletTitleGradientOrient: '', // 可选，渐变色方向，支持top、bottom、left、right
    //   appletTitleGradientStartColor: '',//渐变起始颜色
    //   appletTitleGradientEndColor: '' //渐变结束颜色
    // })
  }
  // @ts-ignore
  // aplus_queue.push({
  //   action: 'aplus.sendPV',
  //   arguments: [{
  //     is_auto: false
  //   }, {
  //   }]
  // })
})

export default router
